
/**
 * Created by wangtong on 2022/2/7
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import ShareCard from "@/components/ShareCard.vue";
import {DynamicApi} from "@/api/dynamic";
import {createDynamicShareData, DynamicShareData} from "@/models";
import BasePage from "@/mixins/BasePage";
import MobileAppDownloader from "@/components/app_downloader/MobileAppDownloader.vue";
import PcAppDownloader from "@/components/app_downloader/PcAppDownloader.vue";
import DynamicShareCard from "@/components/share/DynamicShareCard.vue";

@Component({
    components: {DynamicShareCard, PcAppDownloader, MobileAppDownloader, ShareCard}
})
export default class DynamicSharePage extends BasePage {
    @Prop() id!: any

    shareData: DynamicShareData = createDynamicShareData()

    async created() {
        super.created()
        this.shareData = await DynamicApi.getShareData({share_id: this.id})
    }

    get appSchema(): string {
        return `sandan:///app/dynamic/detail?dynamicId=${this.shareData.record.dynamic_id}`
    }
}
